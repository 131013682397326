
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import portfolioStyle from './portfolio.module.css'
import transactionStyle from './transactions.module.css'

import { getLayout, LayoutContext } from '../components/sg/Layout'
import Header from '../components/Header'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import Updown from '../components/Updown'
import { useState, useEffect, useRef, useContext } from 'react'
import Transaction from '../components/Transaction'
import Link from 'next/link'
import { useLiveHoldings, useHoldingsHistory } from '../hooks/useHoldings'
import useAuth from '../hooks/useAuth'
import renderChart, { defaultChartScale } from '../helpers/renderChart'
import resolveProductUnit, { defaultProductUnit } from '../helpers/resolveProductUnit'
import NumberCount from '../components/NumberCount'
import Loading from '../components/Loading'
import Router, { useRouter } from 'next/router'
import Cards from '../components/sg/Cards'
import Footer from '../components/sg/Footer'
import { useTransactions } from '../hooks/useTransactions'
import { currencies } from '../hooks/usePrices'
import PullToRefresh from '../components/PullToRefresh'
import useUser from '../hooks/useUser'
import useLocalStorageState from 'use-local-storage-state'

let chart
let goldSeries
let silverSeries
let cashSeries
let zeroSeries

export default function Portfolio () {
  const chartElem = useRef()
  const { t } = useTranslation('main')
  const router = useRouter()
  const { locale } = router
  const c = classNames.bind(portfolioStyle)
  const ct = classNames.bind(transactionStyle)

  const [layoutContext, setLayoutContext] = useContext(LayoutContext)
  const { productUnit, chartScale, currency: layoutCurrency } = layoutContext
  const setProductUnit = productUnit => setLayoutContext({ productUnit })
  const setChartScale = chartScale => setLayoutContext({ chartScale })
  const setCurrency = currency => setLayoutContext({ currency })
  const [chartSeries, setChartSeries] = useLocalStorageState('chartSeries', { defaultValue: { Cash: true, AuGrams: true, AgGrams: true } })
  const [percentPriceDiff, setPercentPriceDiff] = useState(true)
  const [goldInfoVisible, setGoldInfoVisible] = useState()
  const [silverInfoVisible, setSilverInfoVisible] = useState()

  const { authToken, authedFetch } = useAuth()
  const { user, account } = useUser()
  const createdDate = new Date(account?.CreateDate)
  const nowDate = new Date()
  const currency = layoutCurrency || account?.PreferredCurrency

  const { time, holdings: { AuGrams, AgGrams }, balances: { Cash: { Amount: cashBalance } }, isLoading: isPriceLoading, updateHoldings } = useLiveHoldings(currency)
  const { productUnitShortLabel, productPriceResolution, productUnitFractionDigits } = resolveProductUnit(productUnit, 1)
  const { holdings: { AuGrams: AuPrices, AgGrams: AgPrices }, balances: { Cash: balances }, isLoading: pricesLoading } = useHoldingsHistory(chartScale, currency)
  AuGrams.productAmount = AuGrams.Amount / productPriceResolution
  AgGrams.productAmount = AgGrams.Amount / productPriceResolution
  const totalHolding = AuGrams.productAmount + AgGrams.productAmount

  const { transactions, isLoading: isTransactionsLoading, isError: isTransactionsError, updateTransactions } = useTransactions(currency, 8)

  const accountValue = AuGrams.Valuation + AgGrams.Valuation + Math.max(cashBalance, 0)

  const cashCalc = Math.max(cashBalance, 0.00001) / Math.max(accountValue, 0.00001) * 100
  const cashPercent = isNaN(cashCalc) ? 100 : cashCalc
  const silverCalc = Math.max(AgGrams.Valuation, 0.00001) / Math.max(accountValue, 0.00001) * 100
  const silverPercent = isNaN(silverCalc) ? 100 : silverCalc

  const handleVerifyEmail = async () => {
    const response = await authedFetch('/api/user/verify-email', { method: 'POST' })
    if (response.ok) {
      window.sessionStorage.setItem('email_' + user.Id, new Date().toISOString())
      setLayoutContext({
        message: t('portfolio-verify-email'),
        timeout: 10000
      })
    }
  }

  useEffect(() => {
    if (user) {
      if (!account.HasAddress && !window.sessionStorage.getItem('address_' + account.AccountId)) {
        window.sessionStorage.setItem('address_' + account.AccountId, new Date().toISOString())
        setLayoutContext({ message: t('portfolio-update-profile') })
        Router.push('/settings/profile')
      } else if ((user.IsExpiringSoon || user.IsExpired || !account.HasBank) && !window.sessionStorage.getItem('id_' + user.Id)) {
        window.sessionStorage.setItem('id_' + user.Id, new Date().toISOString())
        Router.push('/upload')
      } else if (!user.HasVerifiedEmail && !window.sessionStorage.getItem('email_' + user.Id)) {
        handleVerifyEmail()
      }
    }
  }, [user])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    chartElem.current.children[0]?.remove()
    chart = null
  }, [locale, pricesLoading])

  useEffect(() => {
    goldSeries && goldSeries.setData([])
    silverSeries && silverSeries.setData([])
    cashSeries && cashSeries.setData([])
  }, [chartSeries, pricesLoading, authToken])

  useEffect(() => {
    if (!time || !currency || !AuPrices?.length || !AgPrices.length || !balances?.length) {
      return
    }
    if (!chart) {
      [chart, goldSeries, silverSeries, cashSeries, zeroSeries] = renderChart(chartElem.current, locale, currency)
    }
    let i = 0
    const goldData = (chartSeries.Cash && chartSeries.AuGrams && chartSeries.AgGrams)
      ? AuPrices.map(({ time, value }) => ({ time, value: value + AgPrices[i]?.value + balances[i++]?.value })).concat([{ time, value: AuGrams.Valuation + AgGrams.Valuation + cashBalance }])
      : ((chartSeries.Cash && chartSeries.AuGrams)
        ? AuPrices.map(({ time, value }) => ({ time, value: value + balances[i++]?.value })).concat([{ time, value: AuGrams.Valuation + cashBalance }])
        : (chartSeries.AgGrams && chartSeries.AuGrams)
          ? AuPrices.map(({ time, value }) => ({ time, value: value + AgPrices[i++]?.value })).concat([{ time, value: AuGrams.Valuation + AgGrams.Valuation }])
          : (chartSeries.AuGrams && AuPrices.concat([{ time, value: AuGrams.productValue }])))
    i = 0
    const silverData = (chartSeries.Cash && chartSeries.AgGrams)
      ? AgPrices.map(({ time, value }) => ({ time, value: value + balances[i++]?.value })).concat([{ time, value: AgGrams.Valuation + cashBalance }])
      : (chartSeries.AgGrams && AgPrices.concat([{ time, value: AgGrams.productValue }]))
    const cashData = chartSeries.Cash && balances.concat([{ time, value: cashBalance }])

    goldData && goldData.sort((a, b) => a.time < b.time ? -1 : 1)
    silverData && silverData.sort((a, b) => a.time < b.time ? -1 : 1)
    cashData && cashData.sort((a, b) => a.time < b.time ? -1 : 1)

    cashData && cashSeries.setData(cashData)
    silverData && silverSeries.setData(silverData)
    goldData && goldSeries.setData(goldData)
    cashData && zeroSeries.setData(cashData.map(({ time }) => ({ time, value: 0 })))

    chart.timeScale().fitContent()
  }, [time, account, AuPrices, chartSeries, currency])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    let timeout
    const resize = function () {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        try {
          const rect = chartElem.current.getBoundingClientRect()
          chart.resize(rect.width, rect.height)
        } catch (e) { }
      })
    }

    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  useEffect(() => {
    const handleKeyPress = function (e) {
      if (['INPUT', 'SELECT', 'TEXTAREA'].includes(e.target.tagName)) {
        return
      }
      if (e.key === 'b') {
        Router.push('/buy')
      } else if (e.key === 's') {
        Router.push('/sell')
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  const handleTapToScroll = e => {
    const elem = e.currentTarget
    elem.scrollTo({ left: elem.scrollLeft < 1 ? elem.scrollWidth : 0, behavior: 'smooth' })
  }

  const [scrollRight, setScrollRight] = useState(false)
  const handleScrollRight = e => {
    const elem = e.currentTarget
    setScrollRight(elem.scrollLeft >= elem.scrollWidth / 4)
    if (goldInfoVisible || silverInfoVisible) {
      setGoldInfoVisible(false)
      setSilverInfoVisible(false)
    }
  }

  const handleChartSeries = (e, series) => {
    e.stopPropagation()
    const newSeries = Object.assign({}, chartSeries)
    if (!chartSeries[series] || Object.keys(newSeries).reduce((a, v) => a + !!newSeries[v], 0) > 1) {
      newSeries[series] = !chartSeries[series]
      setChartSeries(newSeries)
    }
  }

  // const handleCancel = async () => {
  //   if (window.confirm(t('portfolio-cancelpurchase-confirm'))) {
  //     const purchase = transactions.find(t => t.status === 'Active')
  //     let deposit
  //     if (purchase) {
  //       await authedFetch('/api/gb-savings/purchase/cancel/' + purchase.id, {
  //         method: 'POST'
  //       })
  //       deposit = transactions.find(t => t.type === 'deposit' && t.status === 'Open')
  //       if (deposit) {
  //         await authedFetch('/api/payment-intent', {
  //           method: 'PUT',
  //           body: JSON.stringify({
  //             PaymentIntentId: deposit.id,
  //             Status: 'Cancelled'
  //           })
  //         })
  //       }
  //     }
  //     updateTransactions({ transactions: transactions.filter(t => ![purchase?.id, deposit?.id].includes(t.id)) })
  //     updateHoldings(undefined)
  //   }
  // }

  return (
    <>
      <PullToRefresh onRefresh={() => { updateHoldings(undefined); updateTransactions(undefined) }} isRefreshing={isTransactionsLoading || isPriceLoading} />
      <Header title={t('portfolio-title')}>
        <div className={c('holdingsOuter', { right: scrollRight, loading: isNaN(accountValue) })}>
          <div className={c('holdings')} onScroll={handleScrollRight} onClick={handleTapToScroll}>
            <div className={c('holdingsInner')}>
              <div className={c('account')}>
                <svg className={c('pie')} viewBox='0 0 32 32'>
                  {/* <!-- TODO: Fix circle --> */}

                  <circle className={c('silver')} r='16' cx='16' cy='16' style={{ strokeDashoffset: 300 - silverPercent - (100 - silverPercent) }} />
                  <circle className={c('gold')} r='16' cx='16' cy='16' style={{ strokeDashoffset: 300 - cashPercent - silverPercent - 100 }} />
                  <circle className={c('fiat')} r='16' cx='16' cy='16' style={{ strokeDashoffset: 300 - cashPercent }} />
                </svg>
                <div className={c('fiatvalue')}>
                  <div>
                    {!isNaN(accountValue) && currency
                      ? (
                        <output id='accountValue' className={c('value')}>
                          <NumberCount number={accountValue} options={{ style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 }} />
                        </output>
                      ) : <Loading />}
                  </div>
                  {!isNaN(AuGrams.WeightedCost) && !isNaN(AgGrams.WeightedCost) && !isNaN(accountValue) && AuGrams.WeightedCost + AgGrams.WeightedCost + cashBalance > 0 && currency &&
                    <button
                      title={t('portfolio-togglepercentdiff')}
                      className={c('percentdiff', { up: AuGrams.WeightedCost + AgGrams.WeightedCost <= AuGrams.Valuation + AgGrams.Valuation })}
                      onClick={e => { e.stopPropagation(); setPercentPriceDiff(!percentPriceDiff) }}
                    >
                      {percentPriceDiff
                        ? ((accountValue / (AuGrams.WeightedCost + AgGrams.WeightedCost + cashBalance)) - 1).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : ((AuGrams.Valuation - AuGrams.WeightedCost) + (AgGrams.Valuation - AgGrams.WeightedCost)).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      <Updown number={(AuGrams.Valuation - AuGrams.WeightedCost) + (AgGrams.Valuation - AgGrams.WeightedCost)} />
                    </button>}
                  <label htmlFor='accountValue' className={c('label')}>
                    {t('portfolio-accountvalue')} {currency && '(' + currency + ')'}
                    <br />
                    {!isNaN(AuGrams.WeightedCost) && !isNaN(AgGrams.WeightedCost) && !isNaN(accountValue) && AuGrams.WeightedCost + AgGrams.WeightedCost + cashBalance > 0 ? t('portfolio-accountvaluechange') : ' '}
                  </label>
                </div>
              </div>
              <div className={c('products')}>
                <div className={c('productamount', 'gold')}>
                  <img onClick={e => handleChartSeries(e, 'AuGrams')} className={c('goldbar', { off: !chartSeries.AuGrams })} src='/images/gold.png' alt={t('portfolio-goldbar-alt')} />
                  <div>
                    <output id='productAmount' className={c('value')}>
                      {currency && (AuGrams.Valuation || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </output>
                    <label htmlFor='productAmount' className={c('label')}>
                      {currency && (AuGrams.productAmount || 0).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel + ` ${t('portfolio-gold-productvalue')}`}
                    </label>
                  </div>
                  <button className={c('infobutton')} onClick={e => { e.stopPropagation(); setSilverInfoVisible(false); setGoldInfoVisible(!goldInfoVisible) }}>&#9432;</button>
                </div>
                <div className={c('productinfo', 'gold', { visible: goldInfoVisible })}>
                  <table>
                    <tbody>
                      {AuGrams.productAmount !== undefined && AuGrams.Bonus !== undefined && AuGrams.Bonus > 0 && (
                        <>
                          <tr>
                            <td>{t('portfolio-gold-label')}:</td>
                            <td>{(AuGrams.productAmount - AuGrams.Bonus).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                          </tr>
                          <tr>
                            <td>{t('portfolio-goldreward-label')}:</td>
                            <td>{AuGrams.Bonus.toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td>{t('portfolio-unbilledstoragefees-label')}:</td>
                        <td>{AuGrams.AccumulatedStorage !== undefined && AuGrams.AccumulatedStorage.toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                      </tr>
                      <tr>
                        <td>{t('portfolio-nextstoragefeebill-label')}:</td>
                        <td>{account && new Date(nowDate.getFullYear(), nowDate.getDate() < createdDate.getDate() ? nowDate.getMonth() : nowDate.getMonth() + 1, createdDate.getDate(), createdDate.getHours(), createdDate.getMinutes()).toLocaleDateString(locale)}</td>
                      </tr>
                      <tr>
                        <td>{(0.1 / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} {t('portfolio-minimumannualstoragefeedue-label')}:</td>
                        <td>{account && new Date(nowDate.getTime() + ((365 * 24 * 3600000) - (nowDate.getTime() - createdDate.getTime()) % (365 * 24 * 3600000))).toLocaleDateString(locale)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={c('productamount', 'silver')}>
                  <img onClick={e => handleChartSeries(e, 'AgGrams')} className={c('silverbar', { off: !chartSeries.AgGrams })} src='/images/silver.png' alt={t('portfolio-silverbar-alt')} />
                  <div>
                    <output id='productAmount' className={c('value')}>
                      {currency && (AgGrams.Valuation || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </output>
                    <label htmlFor='productAmount' className={c('label')}>
                      {currency && (AgGrams.productAmount || 0).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel + ` ${t('portfolio-silver-productvalue')}`}
                    </label>
                  </div>
                  <button className={c('infobutton')} onClick={e => { e.stopPropagation(); setGoldInfoVisible(false); setSilverInfoVisible(!silverInfoVisible) }}>&#9432;</button>
                </div>
                <div className={c('productinfo', 'silver', { visible: silverInfoVisible })}>
                  <table>
                    <tbody>
                      {AgGrams.productAmount !== undefined && AgGrams.Bonus !== undefined && AgGrams.Bonus > 0 && (
                        <>
                          <tr>
                            <td>{t('portfolio-silver-label')}:</td>
                            <td>{(AgGrams.productAmount - AgGrams.Bonus).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                          </tr>
                          <tr>
                            <td>{t('portfolio-silverreward-label')}:</td>
                            <td>{AgGrams.Bonus.toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td>{t('portfolio-unbilledstoragefees-label')}:</td>
                        <td>{AgGrams.AccumulatedStorage !== undefined && AgGrams.AccumulatedStorage.toLocaleString(locale, { minimumFractionDigits: productUnitFractionDigits, maximumFractionDigits: productUnitFractionDigits }) + productUnitShortLabel}</td>
                      </tr>
                      <tr>
                        <td>{t('portfolio-nextstoragefeebill-label')}:</td>
                        <td>{account && new Date(nowDate.getFullYear(), nowDate.getDate() < createdDate.getDate() ? nowDate.getMonth() : nowDate.getMonth() + 1, createdDate.getDate(), createdDate.getHours(), createdDate.getMinutes()).toLocaleDateString(locale)}</td>
                      </tr>
                      <tr>
                        <td>{(10 / productPriceResolution).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel} {t('portfolio-minimumannualstoragefeedue-label')}:</td>
                        <td>{account && new Date(nowDate.getTime() + ((365 * 24 * 3600000) - (nowDate.getTime() - createdDate.getTime()) % (365 * 24 * 3600000))).toLocaleDateString(locale)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={c('cashbalance')}>
                  <span onClick={e => handleChartSeries(e, 'Cash')} className={c('dollar', { off: !chartSeries.Cash })}>{currencies.find(c => c.code === currency)?.symbol || '$'}</span>
                  <div>
                    <output id='cashBalance' className={c('value', { negative: cashBalance < 0 })}>
                      {currency && (cashBalance || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </output>
                    <label htmlFor='cashBalance' className={c('label')}>
                      {currency && t('portfolio-cashbalance') + ' (' + currency + ')'}
                    </label>
                  </div>
                  <span />
                </div>
                <div className={c('actions', { onlyone: totalHolding === 0 })}>
                  <Link href='/buy'>
                    <button className={c('buy') + ' goldenshimmer'} title={t('portfolio-buy-assets-title')}>{totalHolding === 0 ? t('portfolio-buy-assets-long') : t('portfolio-buy-assets-short')}</button>
                  </Link>
                  <Link href='/sell'>
                    <button className={c('sell')} title={t('portfolio-sell-assets-title')}>{t('portfolio-sell-assets')}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={c('actions', { onlyone: totalHolding === 0 })}>
            <Link href='/buy'>
              <button className={c('buy') + ' goldenshimmer'} title={t('portfolio-buy-assets-title')}>{totalHolding === 0 ? t('portfolio-buy-assets-long') : t('portfolio-buy-assets-short')}</button>
            </Link>
            <Link href='/sell'>
              <button className={c('sell')} title={t('portfolio-sell-assets-title')}>{t('portfolio-sell-assets')}</button>
            </Link>
          </div>
          {chartScale !== defaultChartScale && <div />}
          {productUnit !== defaultProductUnit && <div />}
          <div className={c('soptions')}>
            <button className={c({ active: chartScale === 'H' })} onClick={() => setChartScale('H')} title={t('portfolio-chartscale-hour')}>{t('portfolio-chartscale-hour-short')}</button>
            <button className={c({ active: chartScale === 'D' })} onClick={() => setChartScale('D')} title={t('portfolio-chartscale-day')}>{t('portfolio-chartscale-day-short')}</button>
            <button className={c({ active: chartScale === 'W' })} onClick={() => setChartScale('W')} title={t('portfolio-chartscale-week')}>{t('portfolio-chartscale-week-short')}</button>
            <button className={c({ active: chartScale === 'M' })} onClick={() => setChartScale('M')} title={t('portfolio-chartscale-month')}>{t('portfolio-chartscale-month-short')}</button>
            <button className={c({ active: chartScale === 'Y' })} onClick={() => setChartScale('Y')} title={t('portfolio-chartscale-year')}>{t('portfolio-chartscale-year-short')}</button>
            <hr style={{ flex: '1 0 .4em' }} />
            <button className={c({ active: productUnit === 'ounce' })} onClick={() => setProductUnit('ounce')} title={t('portfolio-productunitresolution-ounce')}>{resolveProductUnit('ounce').productUnitShortLabel}</button>
            <button className={c({ active: productUnit === 'gram' })} onClick={() => setProductUnit('gram')} title={t('portfolio-productunitresolution-gram')}>{resolveProductUnit('gram').productUnitShortLabel}</button>
            <hr style={{ flex: '0 0 .4em' }} />
            <select id='currency' value={currency} onChange={e => setCurrency(e.target.value)}>
              {currencies.map(c => <option key={c.code} value={c.code}>{c.code}</option>)}
            </select>
          </div>
        </div>
      </Header>
      <div className={c('chart', { loading: pricesLoading })} ref={chartElem} />
      <div className={ct('transactions')}>
        <section>
          {(transactions?.length > 0 || isTransactionsLoading || isTransactionsError) && (
            <>
              <h3>
                <Link href='/transactions'>
                  <a>
                    {t('transactions-seeall')}
                  </a>
                </Link>
                {t('portfolio-recenttransactions')}
              </h3>
            </>
          )}
          <ul>
            {isTransactionsLoading
              ? <li className={ct('empty')}><Loading /></li>
              : (!isTransactionsError
                ? (!transactions?.length
                  ? (
                    <>
                      {user?.IsExpired ? (
                        <li className={ct('helper')}>
                          {t('portfolio-expired-helper')}
                          <button onClick={() => Router.push('/upload')} className={'light ' + ct('button')}>{t('portfolio-expired-proceed')}</button>
                        </li>
                      ) : !account?.HasBank ? (
                        <li className={ct('helper')}>
                          {t('portfolio-noaccount-helper')}
                          <button onClick={() => Router.push('/upload')} className={'light ' + ct('button')}>{t('portfolio-noaccount-proceed')}</button>
                        </li>
                      ) : !user?.HasVerifiedEmail ? (
                        <li className={ct('helper')}>
                          {t('portfolio-verifyemail-helper')}
                          <button onClick={handleVerifyEmail} className={'light ' + ct('button')}>{t('portfolio-verifyemail-proceed')}</button>
                        </li>
                      ) : null}
                      <li className={ct('empty')}>
                        <h3>{t('portfolio-notransactions-title')}</h3>
                        {t('portfolio-notransactions')}
                        <br /><br /><br />
                        <Link href='/buy'>
                          <button className='goldenshimmer' title={t('portfolio-buy-gold-title')}>{t('portfolio-buy-gold-longer')}</button>
                        </Link>
                        <br /><br />
                        <Link href='/sg/tour'><a>{t('portfolio-takethetour')}</a></Link>
                      </li>
                    </>
                  ) : (
                    <>
                      {new Date(transactions[0].time).getTime() > new Date().getTime() - (60000 * 60 * 48) && (transactions.find(t => t.type === 'deposit' && ['Open', 'CustomerSent'].includes(t.status)) && transactions.find(t => t.status === 'Active') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-openpurchase-helper')}
                          {/* <button onClick={handleCancel} className={'light ' + ct('button')}>{t('portfolio-openpurchase-cancel')}</button> */}
                        </li>
                      ) : transactions.find(t => t.status === 'Active' && transactions.find(t => t.type === 'deposit' && t.status === 'Open')) ? (
                        <li className={ct('helper')}>
                          {t('portfolio-openpurchase-helper')}
                          {/* <button onClick={handleCancel} className={'light ' + ct('button')}>{t('portfolio-expiringpurchase-cancel')}</button> */}
                        </li>
                      ) : transactions.find(t => t.type === 'deposit' && t.status === 'Open') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-opendeposit-helper')}
                        </li>
                      ) : transactions.find(t => t.status === 'CustomerSent') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-awaitingfunds-helper')}
                        </li>
                      ) : transactions.find(t => t.type === 'withdrawal' && t.status === 'Open') ? (
                        <li className={ct('helper')}>
                          {t('portfolio-openwithdrawal-helper')}
                        </li>
                      ) : user?.IsExpired ? (
                        <li className={ct('helper')}>
                          {t('portfolio-expired-helper')}
                          <button onClick={() => Router.push('/upload')} className={'light ' + ct('button')}>{t('portfolio-expired-proceed')}</button>
                        </li>
                      ) : !account?.HasBank ? (
                        <li className={ct('helper')}>
                          {t('portfolio-noaccount-helper')}
                          <button onClick={() => Router.push('/upload')} className={'light ' + ct('button')}>{t('portfolio-noaccount-proceed')}</button>
                        </li>
                      ) : !user?.HasVerifiedEmail ? (
                        <li className={ct('helper')}>
                          {t('portfolio-verifyemail-helper')}
                          <button onClick={handleVerifyEmail} className={'light ' + ct('button')}>{t('portfolio-verifyemail-proceed')}</button>
                        </li>
                      ) : null)}
                      {transactions.map(ta => (
                        <li key={ta.time + ta.sku + ta.id}>
                          <Transaction
                            id={ta.id}
                            type={ta.type}
                            cashAmount={ta.value}
                            currency={ta.currency}
                            product={ta.sku}
                            productAmount={ta.amount}
                            productBalance={ta.balance}
                            productUnit={ta.unit}
                            status={ta.status}
                            time={new Date(ta.time)}
                          />
                        </li>
                      ))}
                    </>
                  )
                ) : (
                  <li className={ct('empty')}>
                    {t('portfolio-transactions-error')}
                  </li>
                ))}
          </ul>
        </section>
      </div>
      <Cards />
      <Footer />
    </>
  )
}

Portfolio.requiresAuth = true
Portfolio.getLayout = getLayout


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/portfolio',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  