import useSWR from 'swr'
import useAuth from './useAuth.js'
import { chartScales, defaultChartScale } from '../helpers/renderChart'

export const products = [{ code: 'AuGrams' }, { code: 'AgGrams' }]

export function pricesFilter (prices, scale) {
  const now = new Date()
  const nowSeconds = Math.floor(now.getTime() / 1000) - now.getTimezoneOffset() * 60
  const scaleSeconds = (chartScales.find(c => c.name === scale) || chartScales.find(c => c.name === defaultChartScale)).seconds
  // .log(scaleSeconds, prices.map(p => nowSeconds - p.time))
  return prices.filter(p => nowSeconds - p.time < scaleSeconds)
}

export function priceMapper (item, lookupFunc, attribute) {
  // console.log(Math.floor(new Date(item.Time).getTime() / 1000) - new Date().getTimezoneOffset() * 60)
  return item.error ? {} : {
    time: Math.floor(new Date(item.Time).getTime() / 1000) - new Date().getTimezoneOffset() * 60,
    value: lookupFunc(item)?.[attribute] || 0
  }
}

export function getHoldingEndPoint (chartScale, currency, date) {
  const { interval, datapoints, steps } = chartScales.find(c => c.name === chartScale) || chartScales.find(c => c.name === defaultChartScale)
  date = date ? `&toDate=${new Date(date).toISOString()}` : ''
  currency = currency ? `&currency=${currency}` : ''
  return `/api/gb-savings/holdings?qtyIntervals=${datapoints + 1}&interval=${interval}&intervalSize=${steps}${currency}${date}`
}

export function useHoldingsHistory (chartScale, currency) {
  const { isAuthed, authToken } = useAuth()
  const { data, error, isValidating } = useSWR(isAuthed ? [getHoldingEndPoint(chartScale, currency), authToken] : false, {
    refreshInterval: 60000,
    dedupingInterval: 60000
  })

  currency = currency || data?.[0]?.Currency

  return {
    balances: {
      Cash: (!data?.length ? [] : data)?.map(m => priceMapper(m, m => m.Balances?.[currency] || {}, 'Amount')).reverse()
    },
    holdings: {
      AuGrams: (!data?.length ? [] : data)?.map(m => priceMapper(m, m => m.Holdings ? {
        Amount: (m.Holdings.AuGrams?.Amount || 0) + (m.Holdings.AuGramsBonus?.Amount || 0),
        Bonus: (m.Holdings.AuGramsBonus?.Amount || 0),
        Valuation: (m.Holdings.AuGrams?.Valuation || 0) + (m.Holdings.AuGramsBonus?.Valuation || 0),
        DailyStorage: (m.Holdings.AuGrams?.DailyStorage || 0) + (m.Holdings.AuGramsBonus?.DailyStorage || 0),
        AccumulatedStorage: (m.Holdings.AuGrams?.AccumulatedStorage || 0) + (m.Holdings.AuGramsBonus?.AccumulatedStorage || 0),
        WeightedCost: (m.Holdings.AuGrams?.WeightedCost || 0) + (m.Holdings.AuGramsBonus?.WeightedCost || 0)
      } : {}, 'Valuation') || 0).reverse(),
      AgGrams: (!data?.length ? [] : data)?.map(m => priceMapper(m, m => m.Holdings ? {
        Amount: (m.Holdings.AgGrams?.Amount || 0) + (m.Holdings.AgGramsBonus?.Amount || 0),
        Bonus: (m.Holdings.AgGramsBonus?.Amount || 0),
        Valuation: (m.Holdings.AgGrams?.Valuation || 0) + (m.Holdings.AgGramsBonus?.Valuation || 0),
        DailyStorage: (m.Holdings.AgGrams?.DailyStorage || 0) + (m.Holdings.AgGramsBonus?.DailyStorage || 0),
        AccumulatedStorage: (m.Holdings.AgGrams?.AccumulatedStorage || 0) + (m.Holdings.AgGramsBonus?.AccumulatedStorage || 0),
        WeightedCost: (m.Holdings.AgGrams?.WeightedCost || 0) + (m.Holdings.AgGramsBonus?.WeightedCost || 0)
      } : {}, 'Valuation') || 0).reverse()
    },
    currency: currency,
    isLoading: isValidating,
    isError: error
  }
}

export function useLiveHoldings (currency) {
  const { isAuthed, authToken } = useAuth()
  const { data, error, mutate: updateHoldings } = useSWR(isAuthed ? [`/api/gb-savings/holdings?qtyIntervals=1&interval=minute&intervalSize=1&currency=${currency}`, authToken] : false, {
    refreshInterval: 10000,
    dedupingInterval: 10000
  })

  currency = currency || data?.[0]?.Currency

  return {
    time: data?.[0]?.Time ? Math.floor(new Date(data?.[0]?.Time).getTime() / 1000) - new Date().getTimezoneOffset() * 60 : undefined,
    balances: data?.[0]?.Balances
      ? (data[0].Balances?.[currency]
        ? { Cash: data[0].Balances?.[currency] }
        : { Cash: { Amount: 0, Valuation: 0 } })
      : { Cash: {} },
    holdings: data?.[0]?.Holdings
      ? (Object.keys(data[0].Holdings).length
        ? {
          AuGrams: {
            Amount: (data[0].Holdings.AuGrams?.Amount || 0) + (data[0].Holdings.AuGramsBonus?.Amount || 0),
            Bonus: (data[0].Holdings.AuGramsBonus?.Amount || 0),
            Valuation: (data[0].Holdings.AuGrams?.Valuation || 0) + (data[0].Holdings.AuGramsBonus?.Valuation || 0),
            WeightedCost: (data[0].Holdings.AuGrams?.WeightedCost || 0) + (data[0].Holdings.AuGramsBonus?.WeightedCost || 0),
            AccumulatedStorage: (data[0].Holdings.AuGrams?.AccumulatedStorage || 0) + (data[0].Holdings.AuGramsBonus?.AccumulatedStorage || 0),
            DailyStorage: (data[0].Holdings.AuGrams?.DailyStorage || 0) + (data[0].Holdings.AuGramsBonus?.DailyStorage || 0)
          },
          AgGrams: {
            Amount: (data[0].Holdings.AgGrams?.Amount || 0) + (data[0].Holdings.AgGramsBonus?.Amount || 0),
            Bonus: (data[0].Holdings.AgGramsBonus?.Amount || 0),
            Valuation: (data[0].Holdings.AgGrams?.Valuation || 0) + (data[0].Holdings.AgGramsBonus?.Valuation || 0),
            WeightedCost: (data[0].Holdings.AgGrams?.WeightedCost || 0) + (data[0].Holdings.AgGramsBonus?.WeightedCost || 0),
            AccumulatedStorage: (data[0].Holdings.AgGrams?.AccumulatedStorage || 0) + (data[0].Holdings.AgGramsBonus?.AccumulatedStorage || 0),
            DailyStorage: (data[0].Holdings.AgGrams?.DailyStorage || 0) + (data[0].Holdings.AgGramsBonus?.DailyStorage || 0)
          }
        }
        : { AuGrams: { Amount: 0, Bonus: 0, Valuation: 0, DailyStorage: 0, WeightedCost: 0 }, AgGrams: { Amount: 0, Bonus: 0, Valuation: 0, DailyStorage: 0, WeightedCost: 0 } })
      : { AuGrams: {}, AgGrams: {} },
    currency,
    isLoading: !data && !error,
    isError: error,
    updateHoldings
  }
}
